<i18n locale="th" lang="yaml" >
title.user.admin : "@:enum.user_role.1"
title.user.user : "@:enum.user_role.2"
title.user.technician : "@:enum.user_role.3"
button.add_user : "เพิ่มผู้ใช้งาน"
set_active.title : "นำผู้ใช้งานกลับมาใช้ใหม่"
set_active.content : "คุณต้องการตั้งค่าให้ผู้ใช้งาน {name} สามารถเข้าใช้ระบบได้ใหม่ ?"
set_active.ok : "@:common.ok"
set_active.success : "{name} สามารถใช้งานในระบบได้อีกครั้ง"
set_inactive.title : "ระงับการใช้งาน"
set_inactive.content : "คุณต้องการตั้งค่าให้ผู้ใช้งาน {name} ไม่สามารถใช้ระบบได้ชั่วคราว ?"
set_inactive.ok : "@:common.ok"
set_inactive.success : "{name} จะไม่สามารถใช้งานในระบบได้"
reset_password.success : "ทำการตั้งค่ารหัสผ่านใหม่สำหรับผู้ใช้งาน {name} เรียบร้อย"
</i18n>

<template>
	<div class="page-padding">
		<a-button
			v-if="canCreateUser"
			class="btn-page-action"
			type="primary"
			icon="plus"
			@click="clickAddUser">
			{{$t('button.add_user')}}
		</a-button>
		<a-card :title="$t('title.user.admin')" class="user-list-card">
			<MyCardList
				:num-skeletons="3"
				class="mycard-list-grid user-list"
				:empty="adminUsers.length === 0"
				:loading="loading">
					<UserLiteCard
						slot="skeleton"
						:bordered="true"
						size="small"
						:loading="true"/>

					<UserLiteCard v-for="user in adminUsers"
						:key="user.id"
						:user="user"
						:bordered="true"
						size="small"
						:clickable="true"
						:show-action="true"
						@reset_password="handleUserResetPassword"
						@set_active="handleUserSetActive"
						@set_inactive="handleUserSetInactive">

				</UserLiteCard>
			</MyCardList>
		</a-card>
		<a-card :title="$t('title.user.user')" class="user-list-card">
			<MyCardList
				:num-skeletons="3"
				:empty="normalUsers.length === 0"
				class="mycard-list-grid user-list"
				:loading="loading">
					<UserLiteCard
						slot="skeleton"
						:bordered="true"
						size="small"
						:loading="true"/>

					<UserLiteCard v-for="user in normalUsers"
						:key="user.id"
						:user="user"
						:bordered="true"
						size="small"
						:clickable="true"
						:show-action="true"
						@reset_password="handleUserResetPassword"
						@set_active="handleUserSetActive"
						@set_inactive="handleUserSetInactive">
				</UserLiteCard>
			</MyCardList>
		</a-card>

		<a-card :title="$t('title.user.technician')" class="user-list-card">
			<MyCardList
				:num-skeletons="3"
				:empty="technicianUsers.length === 0"
				class="mycard-list-grid user-list"
				:loading="loading">
					<UserLiteCard
						slot="skeleton"
						:bordered="true"
						size="small"
						:loading="true"/>

					<UserLiteCard v-for="user in technicianUsers"
						:key="user.id"
						:user="user"
						:bordered="true"
						size="small"
						:clickable="true"
						:show-action="true"
						@reset_password="handleUserResetPassword"
						@set_active="handleUserSetActive"
						@set_inactive="handleUserSetInactive">
				</UserLiteCard>
			</MyCardList>
		</a-card>

		<ResetPasswordModal
			:visible="resetPasswordShow"
			:user="resetPasswordUser"
			@reset_password_success="handleUserResetPasswordSuccess"
			@close="handleUserResetPasswordClose"/>

	</div>
</template>

<script>
import PageMixin from "@mixins/PageMixin.vue"
import UserLiteCard from "@components/user/UserLiteCard.vue"
import MyCardList from "@components/common/MyCardList.vue"
import ResetPasswordModal from "@components/user/ResetPasswordModal.vue"

import axios from "axios"
export default {
	components : {
		UserLiteCard , MyCardList , ResetPasswordModal
	} ,
	mixins : [PageMixin] ,
	props : {
		company : {
			type: Object ,
			default : () => []
		}
	} ,
	data() {
		return {
			loading : false,
			users : [] ,
			resetPasswordShow : false,
			resetPasswordUser : {} ,
		}
	} ,
	computed : {
		canCreateUser() {
			return this.company.id && this.$authorize('create','user',{companyId:this.company.id})
		} ,
		adminUsers() {
			const admin = 1
			return this.users.filter((user)=>user.user_role === admin)
		} ,
		normalUsers() {
			const normalUser = 2
			return this.users.filter((user)=>user.user_role === normalUser)
		} ,
		technicianUsers() {
			const technician = 3
			return this.users.filter((user) => user.user_role === technician)
		}
	} ,
	watch: {
		$route : {
			handler(newVal,oldVal){
				if (!this.company || newVal.params.id != this.company.id) {
					this.fetchData()
				}
			}
		}
	} ,
	mounted() {
		this.fetchData()
	} ,
	methods : {
		fetchData() {
			this.loading = true
			const companyId = this.$route.params.id;
			if (!companyId)
				return
			axios.get("/api/companies/"+companyId+"/users").then((response) => {
				this.users = response.data.data.users;
			}).catch((error) => {
				this.fetchError(error)
			}).finally(() => {
				this.loading = false
			})
		} ,
		clickAddUser() {
			this.$router.push({
				name: 'company/user_create' ,
				params : {id : this.company.id}
			})
		} ,
		handleUserResetPassword(user) {
			this.resetPasswordUser = Object.assign({},this.resetPasswordUser,user)
			this.resetPasswordShow = true;
		} ,
		handleUserResetPasswordSuccess(user) {
			const index = this.users.findIndex(element=> element.id == user.id)
			if (index != -1) {
				this.$set(this.users,index,user)
			}
			this.resetPasswordShow = false
			this.$message.success(this.$t('reset_password.success',{"name" : user.username}))
		} ,
		handleUserResetPasswordClose() {
			this.resetPasswordShow = false
		},
		handleUserSetActive(user) {
			this.$confirm({
				title : this.$t('set_active.title') ,
				content : this.$t('set_active.content',{"name" : user.username}) ,
				okText : this.$t('set_active.ok') ,
				maskClosable : true,
				onOk: ()=> {
					axios.post("/api/users/"+user.id+"/active").then((response) => {
						const newUser = response.data.data.user
						const index = this.users.findIndex(element=> element.id == newUser.id)
						if (index != -1) {
							this.$set(this.users,index,newUser)
						}
						this.$message.success(this.$t('set_active.success',{"name" : newUser.username}))
					})
				}
			})
		} ,
		handleUserSetInactive(user) {
			this.$confirm({
				title : this.$t('set_inactive.title') ,
				content : this.$t('set_inactive.content',{"name" : user.username}) ,
				okText : this.$t('set_inactive.ok') ,
				maskClosable : true,
				onOk: ()=> {
					axios.post("/api/users/"+user.id+"/inactive").then((response) => {
						const newUser = response.data.data.user
							const index = this.users.findIndex(element=> element.id == newUser.id)
						if (index != -1) {
							this.$set(this.users,index,newUser)
						}
						this.$message.success(this.$t('set_inactive.success',{"name" : newUser.username}))
					})
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.user-list-card {
	margin-bottom : 32px;
	&::v-deep > .ant-card-body {
		background : #f8f8f8;
		border-top : 1px solid @border-color-base;
	}
}
</style>
