<i18n locale="th" lang="yaml" >
action.user.update_profile : "แก้ไขข้อมูลผู้ใช้งาน"
action.user.reset_password : "รีเซ็ตรหัสผ่าน"
action.user.set_inactive : "ระงับการใช้งาน"
action.user.set_active : "นำกลับมาใช้งาน"
</i18n>

<template>
<a-card
	:loading="loading"
	:hoverable="clickable"
	:bordered="bordered"
	:class="cardCssClasses"
	@click="handleClick">

	<a-dropdown
		v-if="showAction && actionMenus.length"
		:trigger="['click']">
		<a-button
			class="mycard-button-action ant-btn-icon-only"
			type="link"
			@click.stop>
			<ion-icon name="ellipsis-vertical"></ion-icon>
		</a-button>

		<a-menu slot="overlay">
			<a-menu-item v-for="menu in actionMenus" :key="menu.icon"
				:class="menu.class"
				@click="menu.click">
				<a-icon :type="menu.icon" />
				{{menu.title}}
			</a-menu-item>
		</a-menu>
	</a-dropdown>


	<div class="mycard-content">
		<div class="mycard-header">
			<my-router-link name="user/view" :param-value="user.id"
				:target="linkTarget" @click.stop>
				<ImageLoader
					:src="user.image_url"
					class="mycard-image"
					:size="size"
					border-type="circle">
					<a-icon type="user" />
				</ImageLoader>
			</my-router-link>
		</div>
		<ul class="mycard-details">
			<li>
				<span v-if="isTechnician" class="title text-bold">{{user.full_name}}</span>
				<span v-else class="title text-bold">{{user.username}}</span>
			</li>
			<li>
				<span>{{$tenum('user_role',`${user.user_role}`)}}</span>
			</li>
			<li v-if="!isTechnician">
				<a-icon type="user" />
				<span>
					{{user.full_name}}
				</span>
			</li>
			<li>
				<PhoneLink :value="user.phone" />
			</li>
			<li v-if="!isTechnician">
				<EmailLink :value="user.email" />
			</li>
		</ul>
	</div>
	<div v-if="notActiveStatus" class="mycard-invalid-status">
		<a-icon v-if="user.user_status === 'locked'" type="lock" />
		<a-icon v-if="user.user_status === 'inactive'" type="close-circle" />
		{{$tenum('user_status',user.user_status)}}
	</div>
</a-card>
</template>

<script>
import ImageLoader from "@components/common/ImageLoader.vue"
import EmailLink from "@components/common/EmailLink.vue"
import PhoneLink from "@components/common/PhoneLink.vue"
import {Dropdown,Menu} from "ant-design-vue"

export default {
	components : {
		ImageLoader , EmailLink, PhoneLink,
		"a-dropdown" : Dropdown,
		"a-menu" : Menu, "a-menu-item" : Menu.Item ,
	} ,
	props : {
		loading : {
			type: Boolean,
			default : false,
		} ,
		bordered : {
			type: Boolean ,
			default : false,
		} ,
		size : {
			type: String,
			default: "",
		} ,
		user : {
			type: null,
			default : () => []
		} ,
		linkTarget : {
			type : String,
			default : "_self"
		} ,
		clickable :{
			type : Boolean,
			default : false,
		} ,
		showAction : {
			type: Boolean ,
			default : false
		} ,
	} ,
	computed : {
		isTechnician() {
			const technician = 3
			return this.user.user_role === technician
		} ,
		cardCssClasses() {
			return [
				"user-card","mycard","mycard-columns","mycard-with-details",
				{
					"mycard-sm" : this.size == 'small' ,
					"mycard-inactive" : this.notActiveStatus
				}
			]
		} ,
		actionMenus() {
			if (!this.showAction || !this.user.id)
				return []
			const menus = []
			const params = {companyId:this.user.company_id}
			if (this.$authorize('update','user',params) && this.$isAdmin()) {
				menus.push({
					class : "menu-update-profile" ,
					icon : "user" ,
					title : this.$t('action.user.update_profile') ,
					click : this.handleUpdate
				})
			}
			if (this.$authorize('update','user',params) && this.$isAdmin() && !this.$isMe(this.user.id)) {
				menus.push({
					class : "menu-reset-password",
					icon : "key" ,
					title : this.$t('action.user.reset_password') ,
					click : this.handleResetPassword
				})
			}
			if (this.$authorize('update','user',params) && this.$isAdmin() && !this.$isMe(this.user.id)) {
				if (this.user.user_status == 'active') {
					menus.push({
						class : "menu-set-inactive",
						icon : "close-circle" ,
						title : this.$t('action.user.set_inactive'),
						click : this.handleInactive
					})
				} else {
					menus.push({
						class : "menu-set-active",
						icon : "check-circle" ,
						title : this.$t('action.user.set_active'),
						click : this.handleActive
					})
				}
			}
			return menus;
		} ,
		notActiveStatus() {
			return this.user && this.user.id && this.user.user_status != 'active'
		}
	} ,
	methods : {
		handleClick(event) {
			if (!this.clickable)
				return;
			this.$open({name:'user/view' ,params:{id : this.user.id}},this.linkTarget)
		} ,
		handleUpdate() {
			this.$open({name:'user/update',params:{id:this.user.id}})
		} ,
		handleResetPassword() {
			this.$emit('reset_password',this.user)
		} ,
		handleActive() {
			this.$emit('set_active',this.user)
		} ,
		handleInactive() {
			this.$emit('set_inactive',this.user)
		}
	}
}
</script>
<style lang="less" scoped>

.menu-set-active {
	color : @success-color;
}
.menu-set-inactive {
	color : @error-color;
}
</style>
